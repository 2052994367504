import styled from "styled-components";
import colors from "../styles/colors";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import menuLogo from '../assets/images/menu_logo.png'
import koFlag from '../assets/images/ko_flag.png';
import unFlag from '../assets/images/un_flag.png';
import cnFlag from '../assets/images/cn_flag.png';
import jpFlag from '../assets/images/jp_flag.png';
import inFlag from '../assets/images/in_flag.png'
import vnFlag from '../assets/images/vn_flag.png';
import ruFlag from '../assets/images/ru_flag.svg'
import thFlag from '../assets/images/th_flag.png'
import ptFlag from '../assets/images/pt_flag.png'
import frFlag from '../assets/images/fr_flag.png'

import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import Sheet from "react-modal-sheet";
import VideoModal from "../components/VideoModal";
// import logo from "../assets/images/icons/mainLogo.svg";
// import languageIcon from "../assets/images/icons/language.png";
// import orederIcon from "../assets/images/icons/orderList.svg";
// import cartIcon from "../assets/images/icons/myCart.svg";

const ThumbNailBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ImageBox = styled.div`
  width: 150px;
  position: relative;
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
// const LeftBox = styled.div`
//   position: absolute;
//   left: 10px;
//   bottom: 10px;
//   color: #fff;
//   cursor: pointer;
// `;
// const SNSBox = styled.div`
//   color: #fff;
// `;
// const LinkImageBox = styled.div`
//   width: 30px;
//   height: 30px;
//   border-radius: 5px;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(255, 255, 255, 0.3);
//   margin: 0 5px;
//   cursor: pointer;
// `;
// const SubImage = styled.img`
//   margin: 0 5px;
//   vertical-align: bottom;
//   cursor: pointer;
// `;

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  min-height: 100dvh;
  background: ${colors.tabMenu};
  padding: 20px;
`;

const Title = styled.h1`
  width: 100%;
  color: #333333;
  text-align: left;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
`;

const LanguageBox = styled.li`
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  &:active {
    background: rgba(0, 0, 0, 0.25);
  }
`;

const LanguageText = styled.div`
  flex: 1;
  padding-left: 25px;
  font-size: 16px;
  text-align: left;
`;

const FlagWrapper = styled.div`
  width: 80px;
  height: 64px;

    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Flag = styled.div`
  position: relative;
  padding-top: 100%;
  //border-radius: 100%;
  overflow: hidden;
`;
const Centered = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //-webkit-transform: translate(50%, 50%);
  //-ms-transform: translate(50%, 50%);
  transform: translate(50%, 50%);

  img {
      border: 1px solid #bebebe;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;
const InnerDiv= styled.div`
  max-width: 720px;
  margin: auto;
`

const Menu = ({ speed }) => {
    const {t} = useTranslation("menu");
    const navigate = useNavigate();
    const selectLanguage = async (lang) => {
        await localStorage.setItem('lang', lang);
        await i18n.changeLanguage(lang);
        // await navigate('/menu');
        openModal();
    }

    const [modalOpen, setModalOpen] = useState(false);

    const openModal = (item) => {
        setModalOpen(true);
    }

    return (
        <Wrapper>
            <InnerDiv>
                <ThumbNailBox>
                    <ImageBox>
                        <MainImage
                          src={menuLogo}
                        />
                    </ImageBox>
                </ThumbNailBox>
                <Sheet isOpen={modalOpen} onClose={() => setModalOpen(false)} detent="content-height">
                    <Sheet.Container>
                        <Sheet.Header/>
                        <Sheet.Content>
                            <Sheet.Scroller draggableAt="both">
                                <VideoModal onClose={() => setModalOpen(false)} speed={speed}/>
                            </Sheet.Scroller>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop/>
                </Sheet>
            <ul>
                <LanguageBox onClick={() => selectLanguage('ko')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={koFlag} alt={'flag of Korea'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        한국어<br/>
                        Korean
                    </LanguageText>
                </LanguageBox>
                <LanguageBox onClick={() => selectLanguage('en')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={unFlag} alt={'flag of US'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        영어<br/>
                        English
                    </LanguageText>
                </LanguageBox>
                <LanguageBox onClick={() => selectLanguage('cn')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={cnFlag} alt={'flag of China'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        중국어<br/>
                        Simplified Chinese
                    </LanguageText>
                </LanguageBox>
                <LanguageBox onClick={() => selectLanguage('jp')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={jpFlag} alt={'flag of Japan'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        일본어<br/>
                        Japanese
                    </LanguageText>
                </LanguageBox>
                <LanguageBox onClick={() => selectLanguage('th')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={thFlag} alt={'flag of thai'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        태국어<br/>
                        Thai
                    </LanguageText>
                </LanguageBox>
                <LanguageBox onClick={() => selectLanguage('vn')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={vnFlag} alt={'flag of vietnam'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        베트남어<br/>
                        Vietnamese
                    </LanguageText>
                </LanguageBox>
                <LanguageBox onClick={() => selectLanguage('In')}>
                    <FlagWrapper>
                        <Flag>
                            <Centered>
                                <img src={inFlag} alt={'flag of indonesia'}/>
                            </Centered>
                        </Flag>
                    </FlagWrapper>
                    <LanguageText>
                        인도네시아어<br/>
                        Indonesian
                    </LanguageText>
                </LanguageBox>
            </ul>
            </InnerDiv>
        </Wrapper>
    )
}

export default Menu;
