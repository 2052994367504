import styled from "styled-components";
import colors from "../styles/colors";
import ThumbNail from "../components/ThumbNail";
import {useState} from "react";
import ReactPlayer from "react-player";
import examples from '../assets/example.json';
import {useAlert} from "react-alert";
import MenuList2 from "../components/MenuList2";
import Sheet from "react-modal-sheet";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import languageIcon from '../assets/images/icons/language.png';
import axios from "axios";
import parse from 'html-react-parser';
import VideoModal from "../components/VideoModal";

const Wrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  background: ${colors.tabMenu};
`;

const ListBox = styled.div`
  margin-top: 10px;
`;

const ModalTitle = styled.h1`
  font-size: 25px;
  margin-top: 10px;
  color: #333333;
  text-align: center;
`;

const ModalDescOuter =  styled.div`
  //flex: 1;
  //font-size: 18px;
  padding : 10px;
    border-bottom: 1px solid black;

`;

const ModalDescription = styled.div`
  //flex: 1;
  //font-size: 18px;
  padding-top: 10px;
    padding-bottom: 10px;
  text-align: left;
  //margin: 10px;
  max-height: 130px;
  overflow: scroll;
`;

const ModalDescInner = styled.div`
  //flex: 1;
  font-size: 18px;
    position: sticky;
    display: block;
  //padding: 10px;
  text-align: left;
  margin: 10px;
  //border-bottom: 1px solid black;
  height: 100%;    
  overflow: scroll;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 720px;
  bottom: 0;
`;
const ButtonBox = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
`;
const OrderButton = styled.button`
  border: 0;
  padding: 10px;
  flex: 1;
  margin: 5px;
  border-radius: 7px;
  color: white;
  font-weight: bolder;
  max-width: 200px;
  height: 50px;
  font-size: 25px;
`;
const VideoBox = styled.div`
  //min-height: 150px;
  //margin: 10px;
  position: relative;
  //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  aspect-ratio: 1920/1080;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const ModalContainer = styled.div`
  width: 100%;
    height: 80vh;
  position: relative;
  padding-bottom: 75px;
    padding-left: 20px;
    padding-right: 20px;
    
`
const ModalWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  position: relative;
  margin: auto;
`
const InnerDiv= styled.div`
  max-width: 720px;
  margin: auto;
`
//
// const VideoModal = ({item, onClose, onOrder}) => {
//     const {t: uiTranslation} = useTranslation("ui");
//     const {t: menuTranslation, i18n} = useTranslation("menu");
//     return (
//         <ModalContainer>
//             <ModalWrapper>
//                 <ModalTitle>
//                     {menuTranslation(item.title)}
//                 </ModalTitle>
//                 <VideoBox>
//                     <ReactPlayer
//                         className="react-player"
//                         url={`/video/${i18n.language}/${item.videoURL}`} width={'100%'} height={'100%'}
//                         controls playsinline playing loop
//                     />
//                 </VideoBox>
//                 <ModalDescOuter>
//                 <ModalDescription>
//                     {parse(menuTranslation(item.description))}
//                 </ModalDescription>
//                 </ModalDescOuter>
//                 <ButtonWrapper>
//                     <ButtonBox>
//                         <OrderButton style={{background: 'gray'}}
//                                      onClick={onClose}>{uiTranslation("CLOSE")}</OrderButton>
//                         {/*<OrderButton style={{background: '#3a7bdf'}}*/}
//                         {/*             onClick={() => onOrder(item)}>{uiTranslation("ORDER")}</OrderButton>*/}
//                     </ButtonBox>
//                 </ButtonWrapper>
//             </ModalWrapper>
//         </ModalContainer>
//     )
// }

const ChangeLanguageButton = styled.button`
  background: url(${languageIcon});
  background-position: left center;
  background-size: 25px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  border: none;
  font-size: 20px;
  color: #333333;
`;

const Menu = () => {
    const alert = useAlert();
    const {t: uiTranslation} = useTranslation("ui");
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalItem, setModalItem] = useState({title: '', description: ''});
    const [cart, setCart] = useState(new Map());
    const handleOrder = (item) => {
        setModalOpen(false);
        addCart(item);
        setTimeout(() => alert.success(uiTranslation("MENU_ADDED")), 100);
    }
    const openModal = (item) => {
        setModalItem(item);
        setModalOpen(true);
    }
    const addCart = async (item) => {
        const newCart = cart;
        if( cart.has(item.kitchenName) ) {
            newCart.set(item.kitchenName, cart.get(item.kitchenName)+1);
        } else {
            newCart.set(item.kitchenName, 1);
        }
        setCart(newCart);
    };

    const handleSendOrder = async () => {
        if( cart.size === 0 ) {
            alert.error("EMPTY CART");
        } else {
            await axios.post("http://192.168.50.100:3030/requestPrint", {
                orderList: Array.from(cart, ([key, value]) => {
                    return { name: key, amount: value}
                })
            });
            alert.success("ORDER SENDED");
            console.info(cart);
            setCart(new Map());
        }
    }

    return (
        <Wrapper>
            <ThumbNail />
          <InnerDiv>
            {/*<ChangeLanguageButton onClick={() => navigate('/')}>*/}
            {/*    {uiTranslation("CHANGE_LANGUAGE")}*/}
            {/*</ChangeLanguageButton>*/}
            <ListBox>
                <MenuList2
                    itemList={examples}
                    onVideoClick={openModal}
                    onOrderClick={handleOrder}
                />
            </ListBox>
            <Sheet isOpen={modalOpen} onClose={() => setModalOpen(false)} detent="content-height">
                <Sheet.Container>
                    <Sheet.Header/>
                    <Sheet.Content>
                        <Sheet.Scroller draggableAt="both">
                            <VideoModal item={modalItem} onClose={() => setModalOpen(false)} onOrder={handleOrder}/>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop/>
            </Sheet>
          </InnerDiv>
        </Wrapper>
    )
}

export default Menu;
