import React from 'react';
import styled, {css} from "styled-components";
import colors from "../styles/colors";
import logo from "../assets/images/icons/mainLogo.svg"
import languageIcon from "../assets/images/icons/language.png";
import orederIcon from "../assets/images/icons/orderList.svg";
import cartIcon from "../assets/images/icons/myCart.svg";

import {useNavigate} from "react-router-dom";
// import languageIcon from '../assets/images/icons/language.png';

const Wrapper = styled.div`
  position: relative;
  
  padding: 16px;
  background-color:${colors.topThumbNail};
  height: 60px;
  .Toastify__toast-container--bottom-center {
    bottom: 50%;
  }
`;

const ThumbNailBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ImageBox = styled.div`
  width: 80px;
  position: relative;
`;

const OptionBox = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
// const LeftBox = styled.div`
//   position: absolute;
//   left: 10px;
//   bottom: 10px;
//   color: #fff;
//   cursor: pointer;
// `;
// const SNSBox = styled.div`
//   color: #fff;
// `;
// const LinkImageBox = styled.div`
//   width: 30px;
//   height: 30px;
//   border-radius: 5px;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(255, 255, 255, 0.3);
//   margin: 0 5px;
//   cursor: pointer;
// `;
// const SubImage = styled.img`
//   margin: 0 5px;
//   vertical-align: bottom;
//   cursor: pointer;
// `;

const ChangeLanguageButton = styled.button`
  background: url(${languageIcon});
  background-position: center center;
  background-size: 25px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 25px;
  border: none;
  font-size: 20px;
  color: #333333;
`;
const OrderIcons = styled.button`
   background: url(${orederIcon});
  background-position: center center;
  background-size: 30px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border: none;
  font-size: 20px;
  color: #333333;
`;

const CartIcon = styled.button`
   background: url(${cartIcon});
  background-position: center center;
  background-size: 30px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border: none;
  font-size: 20px;
  color: #333333;
`;

const ThumbNail = () => {
  const navigate = useNavigate();

  return (
        <Wrapper>
            <ThumbNailBox>
                <ImageBox>
                    <MainImage
                        src={logo}
                    />
                </ImageBox>
              <OptionBox>
                <ChangeLanguageButton onClick={() => navigate('/')} />
                <OrderIcons />
                <CartIcon />

              </OptionBox>

            </ThumbNailBox>
        </Wrapper>
    );
}

export default ThumbNail;
