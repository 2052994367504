import logo from './logo.svg';
import './App.css';
import Menu from "./pages/Menu";
import {Route, Routes, useNavigate} from "react-router-dom";
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import SelectLanguages from "./pages/SelectLanguages";
import {useEffect} from "react";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const options = {
    // you can also just use 'bottom center'
    position: positions.MIDDLE,
    timeout: 1200,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE,
}

function App() {
    const { i18n } = useTranslation();
    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if(lang)
            i18n.changeLanguage(lang);
    }, []);
    return (
        <AlertProvider template={AlertTemplate} {...options}>
            <div className="App">
                <Routes>
                    <Route path="/menu" element={<Menu/>}/>
                    <Route path="/" element={<SelectLanguages/>}/>
                    {/*<Route path="/slow" element={<SelectLanguages speed={'slow'}/>}/>*/}
                </Routes>
            </div>
        </AlertProvider>
    );
}

export default App;
