import React, {useRef} from 'react';
import styled from "styled-components";
import colors from '../styles/colors';
import {useTranslation} from "react-i18next";
import cartLogo from "../assets/images/icons/cartLogo.svg"

const Wrapper = styled.ul`
  width: 100%;
  max-width: 768px;
  position: relative;
  display: grid;
  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ItemDiv = styled.div`
    width: calc(100% - 20px);
    margin: 10px;
    border-radius: 5px;
    display: flex;
    //height: 300px;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #C4C4C4;
    //gap: 15px;
    //padding: 10px;
`



const ImageBox = styled.div`
  width: 100%;
  height: 160px;
  //margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  position: relative;
  background: ${props => `url(${props.bg}) center;`};
  background-size: cover;

  &::after {
    display: block;
    content: "";
    padding-bottom: 100%;
  }
`;
const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    //height: 100%;
    align-items: center;
    padding: 15px;
    gap: 15px;
`;

const ContentTopBox  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    //height: 40px;
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
`
const CartBox = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: #F5B618;
  border-radius: 7px;
`
const InfoTitle = styled.div`
  text-align: center;
  width: 100%;
  color: black;
  font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: bold;

`

const InfoPrice = styled.div`
  text-align: center;
  width: 100%;
  color: black;
  font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const OrderButton = styled.button`
  border: 0;
  padding: 10px;
  flex: 1;
  margin: 5px;
  border-radius: 7px;
  color: white;
  font-weight: bolder;
  font-size: 15px;
  background: ${props => `${props.bgColor}`};
`;

const Item = ({item, onVideoClick, onOrderClick}) => {
    const { t: uiTranslation} = useTranslation("ui");
    const { t: menuTranslation} = useTranslation("menu");
    return (

          <ItemDiv >

          <ContentBox>
              <ContentTopBox>
                  <TitleBox>
                      <InfoTitle>
                          {menuTranslation(item.title)}
                      </InfoTitle>
                      <InfoPrice>
                          {menuTranslation(item.price)}
                      </InfoPrice>
                  </TitleBox>
                  <CartBox onClick={()=>onVideoClick(item)}>
                    <img style={{"width":"100%"}} src={cartLogo}/>
                  </CartBox>
              </ContentTopBox>

          {/*<ButtonBox>*/}
          {/*    <OrderButton id={item.title} bgColor={'#ef0e0e'} onClick={()=>onVideoClick(item)}>{uiTranslation("VIDEO_INFO")}</OrderButton>*/}
          {/*    /!*<OrderButton bgColor={'#3a7bdf'} onClick={()=>onOrderClick(item)}>{uiTranslation("ORDER")}</OrderButton>*!/*/}
          {/*</ButtonBox>*/}
              <ImageBox bg={item.image} />

          </ContentBox>
          </ItemDiv>

    )
}
const MenuList2 = (props) => {
    const {itemList, onVideoClick, onOrderClick} = props;
    return (
        <Wrapper>
            {
                itemList.map((item, index) => (
                    <Item
                        key={`item-${index}`}
                        item={item}
                        onVideoClick={onVideoClick}
                        onOrderClick={onOrderClick}
                    />
                ))
            }
        </Wrapper>
    )
}

export default MenuList2;
